<template>
	<a-layout id="components-layout-demo-custom-trigger">
		<a-layout-sider
				v-model:collapsed="collapsed"
				:trigger="null"
				collapsible
				width="160"
				class="sider"
		>
			<div class="user">
				<IdcardOutlined/>
				{{ user }}
			</div>
			<a-menu
					theme="dark"
					mode="inline"
					v-model:openKeys="openKeys"
					:selectedKeys="[$route.path]"
					class="menu"
			>
				<!-- 个人空间 -->
				<a-sub-menu key="sub1" v-if="leaderShow">
					<template #title>
						<span><user-outlined/>个人空间</span>
					</template>
					<a-menu-item key="/" v-if="show">
						<router-link to="/">用户管理</router-link>
					</a-menu-item>
					<a-menu-item key="/taskManage">
						<router-link to="/taskManage">任务管理</router-link>
					</a-menu-item>
					<a-menu-item key="/messageManage">
						<router-link to="/messageManage">消息通告</router-link>
					</a-menu-item>
					<a-menu-item key="/uploadFile" v-if="pilotShow">
						<router-link to="/uploadFile">上传文件</router-link>
					</a-menu-item>
					<a-menu-item key="/uploadTable" v-if="pilotShow">
						<router-link to="/uploadTable">上传列表</router-link>
					</a-menu-item>
				</a-sub-menu>

				<!-- 智慧巡湾 -->
				<a-menu-item key="/wisdomCruiseBay" v-if="managerLeader()">
					<router-link to="/wisdomCruiseBay">
						<AlertOutlined/>
						<span>智慧巡湾</span>
					</router-link>
				</a-menu-item>

				<!-- 鸟瞰东疆 -->

				<a-menu-item key="/birdSeeEasternTerritory">
					<router-link to="/birdSeeEasternTerritory">
						<video-camera-outlined/>
						<span>鸟瞰东疆</span>
					</router-link>
				</a-menu-item>

				<!-- 统计报表 -->
				<a-sub-menu key="sub2" v-if="leaderShow">
					<template #title>
						<span><BarChartOutlined/>统计报表</span>
					</template>
					<a-menu-item key="/discoveryReport" v-if="pilotShow">
						<router-link to="/discoveryReport">巡查报表</router-link>
					</a-menu-item>
					<a-menu-item key="/cleannessReport" v-if="cleanerShow">
						<router-link to="/cleannessReport">清理报表</router-link>
					</a-menu-item>
					<a-menu-item key="/weeklyReport" v-if="show">
						<router-link to="/weeklyReport">生成周报</router-link>
					</a-menu-item>
				</a-sub-menu>

				<!-- 人工核查 -->
				<a-menu-item key="/manualCheck" v-if="preShow">
					<router-link to="/manualCheck">
						<UsergroupAddOutlined/>
						<span>人工核查</span>
					</router-link>
				</a-menu-item>

				<!-- 预测模块 -->
				<a-menu-item key="/prediction" v-if="cleanerShow && pilotShow">
					<router-link to="/prediction">
						<FileGifOutlined/>
						<span>预测模块</span>
					</router-link>
				</a-menu-item>

				<!-- 魅力东疆 -->
				<a-menu-item key="/charmingEasternTerritory" v-if="onlyAdminShow">
					<router-link to="/charmingEasternTerritory">
						<SketchOutlined/>
						<span>魅力东疆</span>
					</router-link>
				</a-menu-item>
			</a-menu>
		</a-layout-sider>
		<a-layout>
			<a-layout-header class="header">
				<div>
					<img src="../assets/title.svg" alt="图标"/>
				</div>
				<a-button type="danger" class="quit" @click="quit" style="margin-left: 100px;">退出登录</a-button>
			</a-layout-header>
			<a-layout-content class="content">
				<router-view></router-view>
			</a-layout-content>
		</a-layout>
	</a-layout>
</template>

<script>
	import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
	import {createVNode, defineComponent} from "vue";
	import {Modal} from "ant-design-vue";
	import {message} from "ant-design-vue";
	import {
		UserOutlined,
		VideoCameraOutlined,
		BarChartOutlined,
		AlertOutlined,
		UsergroupAddOutlined,
		FileGifOutlined,
		SketchOutlined,
		IdcardOutlined,
	} from "@ant-design/icons-vue";
	export default defineComponent({
		name: "Admin",
		components: {
			UserOutlined,
			VideoCameraOutlined,
			BarChartOutlined,
			AlertOutlined,
			UsergroupAddOutlined,
			FileGifOutlined,
			SketchOutlined,
			IdcardOutlined,
		},
		data() {
			return {
				collapsed: false,
				openKeys: ["sub1", "sub2"], //默认展开sub几['sub1']
				user: "",
				preShow: false,
				show: true,
				pilotShow: true,
				cleanerShow: true,
				leaderShow: true,
        onlyAdminShow: true
			};
		},
		methods: {
			managerLeader() {
				if (this.$storage.get('userinfo').data.role == 0 || this.$storage.get('userinfo').data.role == 3) {
					return true;
				} else return false;
			},
			quit() {
				let that = this;
				Modal.confirm({
					title: "请确认是否退出登录？",
					icon: createVNode(ExclamationCircleOutlined),
					content: createVNode("div", {style: "color: red;"}, ""),
					okText: "确定",
					cancelText: "取消",
					onOk() {
						let api = "http://39.100.158.75:8080/user/logout";
						that
							.$axios({
								url: api,
								method: "post",
								headers: {
									"Content-Type": "application/x-www-form-urlencoded",
								},
								params: {
									token: that.$storage.get("userinfo").data.token,
								},
							})
							.then((response) => {
								message.info(response.data.message);
								that.$storage.remove("userinfo");
								that.$router.push({
									path: "/login",
								});
							});
					},
					onCancel() {
					},
				});
			},
		},
		mounted() {
		  if (this.$storage.get("userinfo").data.role !== 0) {
		    this.onlyAdminShow = false;
      }
			if(this.$storage.get("userinfo").data.role < 2) {
				this.preShow = true;
			}
			if (this.$storage.get("userinfo").data.role == 3) {
				this.leaderShow = false;
			}
			if (
				this.$storage.get("userinfo").data.role == 1 ||
				this.$storage.get("userinfo").data.role == 2
			) {
				this.show = false;
			}
			if (this.$storage.get("userinfo").data.role === 1) {
				this.cleanerShow = false;
			}
			if (this.$storage.get("userinfo").data.role === 2) {
				this.pilotShow = false;
			}
			if (this.$storage.get("userinfo").data.role === 0) {
				this.user = "管理员";
			} else if (
				this.$storage.get("userinfo").data.role === 1 ||
				this.$storage.get("userinfo").data.role == 2
			) {
				this.user = "普通用户";
			} else {
				this.user = "领导";
			}
		},
	});
</script>

<style lang="scss" scoped>
	#components-layout-demo-custom-trigger {
		height: 100%;

		.user {
			background: #001529;
			width: 80%;
			height: 25px;
			line-height: 25px;
			padding-top: 2px;
			text-align: center;
			margin: 5px auto;
			color: #fff;
			font-size: 16px;
			user-select: none;
		}

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #fff;
			div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 300px;
				height: 100%;
        margin-left: 40px;
				img {
					margin-left: -70px;
					width: 130px;
					height: 130px;
				}

				.a-button {
					color: rgba(255, 255, 255, 1);
					background-color: rgba(0, 128, 22, 0.8);
					margin-left: -70px;
				}
			}
		}

		.content {
			margin: 9px 9px;
		}
	}

	.upload-img {
		display: block;
		margin-top: 20px;
		border: 1px solid pink;
		height: 100px;
		width: 300px;
	}
</style>
